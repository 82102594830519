<template>
  <div>地址</div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {},
  components: {},
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
}
</script>

<style lang="less" scoped></style>
